import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PrivateRoute from "./PrivateRoute";
import Dashboard from "../Layout/Dashboard";
import Forms from "../Layout/Forms";
import Login from "../Layout/Login";

class MasterRouter extends Component {
  render() {
    const { i18n } = this.props;
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          {/* Forms: Check if the current path is non english and redirect if so */}
          <Route path="/partner-agreement" component={Forms} />
          <Route
            path="/partnervertrag"
            render={() => {
              i18n.changeLanguage("de");
              return <Redirect to="/partner-agreement" />;
            }}
          />
          <Route path="/request-certification" component={Forms} />
          <Route
            path="/zertifizierung-anfordern"
            render={() => {
              i18n.changeLanguage("de");
              return <Redirect to="/request-certification" />;
            }}
          />
          <Route path="/contract-data-processing" component={Forms} />
          <Route
            path="/auftragsdatenverarbeitung"
            render={() => {
              i18n.changeLanguage("de");
              return <Redirect to="/contract-data-processing" />;
            }}
          />
          <Route
            path="/contrato-de-tratamiento-de-datos"
            render={() => {
              i18n.changeLanguage("es");
              return <Redirect to="/contract-data-processing" />;
            }}
          />

          <PrivateRoute path="/" component={Dashboard} />
          <Redirect exact from="" to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default withTranslation()(MasterRouter);
