import React from "react";
import { Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PartnerFormDocusign from "components/Modules/Forms/PartnerFormDocusign";
import RequestCertification from "components/Modules/Forms/RequestCertification";
import ContractDataProcessing from "components/Modules/Forms/ContractDataProcessing";

function RouteBuilder(props) {
  const { i18n } = useTranslation();

  const appRouteMapping = [
    {
      route: "/request-certification",
      breadcrumb: "Request Certification",
      component: () => <RequestCertification language={i18n.language} />,
    },
    {
      route: "/partner-agreement",
      breadcrumb: "Partner Agreement",
      component: () => <PartnerFormDocusign language={i18n.language} />,
    },
    {
      route: "/contract-data-processing",
      breadcrumb: "Home",
      component: () => <ContractDataProcessing language={i18n.language} />,
    },
  ];

  return appRouteMapping.map(({ route, exact, component }, key) => {
    return (
      <Route key={route} exact={exact} path={route} component={component} />
    );
  });
}

export default RouteBuilder;
