import React from "react";
import { useTranslation } from "react-i18next";
import "@fontsource/barlow-condensed/700.css";
import { Container, Toolbar, Typography } from "@material-ui/core";
import TimelineGraph from "./TimelineGraph/TimelineGraph";
import DataProtectionInfo from "./DataProtectionInfo/DataProtectionInfo";

export default function ContractDataProcessing() {
  const { t, i18n } = useTranslation();

  const urlGerman =
    "https://eu.services.docusign.net/webforms-ux/v1.0/forms/4a457b57f2c248e394715113b0c25e1e";
  const urlEnglish =
    "https://eu.services.docusign.net/webforms-ux/v1.0/forms/c89b74a48faebd1b6e83070117015097";
  const urlSpanish =
    "https://eu.services.docusign.net/webforms-ux/v1.0/forms/b84817a5d7b16c6c56ee8b96c6952dc2";

  const docusingUrl =
    i18n.language === "de"
      ? urlGerman
      : i18n.language === "es"
      ? urlSpanish
      : urlEnglish;

  return (
    <div style={{ overflow: "hidden" }}>
      <Container
        component="main"
        maxWidth="lg"
        sx={{ my: 2, overflow: "hidden" }}
      >
        <Toolbar />
        <Typography
          variant="h4"
          style={{
            fontWeight: 700,
            fontFamily: "Barlow Condensed, sans-serif",
            letterSpacing: -1,
            textTransform: "uppercase",
            marginTop: 16,
            fontSize: "2.125rem",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          {t("contractDataProcessingTitle")}
        </Typography>

        <TimelineGraph />
      </Container>
      <div style={{ width: "100vw", height: "800px", overflow: "hidden" }}>
        <iframe
          src={docusingUrl}
          style={{
            border: "none",
            overflow: "hidden",
            width: "100vw",
            height: "100%",
          }}
          title="Contract-data-processing-form"
        />
      </div>
      <Container maxWidth="lg" sx={{ py: 2, overflow: "hidden" }}>
        <DataProtectionInfo />
      </Container>
    </div>
  );
}
