import React from 'react';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import UpdateProfilePopup from 'components/Layout/Partial/UpdateProfilePopup.jsx/UpdateProfilePopup';

//import { makeStyles } from '@material-ui/core/styles';

/*
const useStyles = makeStyles(theme => ({
}));
*/

function Home() {
   // const classes = useStyles();
    const history = useHistory();

    // history.push("/support/ticket/list");

    return (
        <>
        <UpdateProfilePopup onComplete={()=> history.push("/support/ticket/list")}/>
        {/* <Typography variant="h6">
            Dashboard Home

        </Typography> */}
        </>
    );
}

export default Home;