import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    root: {},
});

function AlertDialog(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        title,
        text,
        onClose,
        buttonText=t("close"),
    } = props;
    const [ openState, setOpenState] = React.useState(props.openState);

    useEffect(() => {
        setOpenState(props.openState);
    }, [props.openState]);

    function handleClose(){
        setOpenState(false);
        if( typeof onClose==='function' ) {
            onClose();
        }
    }

    return (
        <Dialog
            open={openState}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><strong>{title}</strong></DialogTitle>
            <DialogContent>
                <DialogContentText color="text.primary" id="alert-dialog-description" style={{ whiteSpace: "pre-line" }}>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;