import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    zIndex: 110,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255,255,255,0.6)",
  },
}));

export default function LoadingSpinner({ loading = true, delay = 500 }) {
  const classes = useStyles();

  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? `${delay}ms` : "0ms",
      }}
      unmountOnExit
    >
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </Fade>
  );
}
