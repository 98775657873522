import React, {useEffect} from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {TextField as MuiTextField} from "@material-ui/core";
import TextField from "../../../../../Shared/Form/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import { usePerm } from "../../../../../../context/permission";
import Button from "@material-ui/core/Button";
import DoneIcon from '@mui/icons-material/Done';
import { serialize } from 'object-to-formdata';
import { useTranslation, Trans } from 'react-i18next';
import camelCase from 'camelcase';
import {useHistory} from "react-router-dom";
import FormHelperText from '@mui/material/FormHelperText';
import RichTextEditor from './RichTextEditor';

import { useAppLoadingState } from "../../../../../../context/appLoadingState";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import {FormControl} from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileUploader from "../FileUploader";
import GeneralPermissionDeniedMessage
    from "../../../../../Shared/StaticContainerMessage/GeneralPermissionDeniedMessage";
import MemoUserItem from "../../../../..//Shared/Ticket/Create/List/MemoUserItem";

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    gridContainer: {
        margin: theme.spacing(1),
    },
    gridItem: {
        margin: theme.spacing(1),
        marginRight:  theme.spacing(3),
    },
    dropdown1: {
        // background:'#fff',
        minWidth: 450,
    },
    dropdown2: {
        // background:'#fff',
        minWidth: 250,
    },
    dropdown3: {
        // background:'#fff',
        minWidth: 600,
    },
    titleTextfield: {
        // background:'#fff',
        width: '100%',
        marginTop: theme.spacing(1)
    },
    descriptionTextfield: {
        // background:'#fff',
        width: '100%',
        marginTop: theme.spacing(1)
    },
    formControl: {
        marginLeft: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    multiSelect: {
        // background:'#fff',
        // height: "4.5vh",
        width: 450,
    },
    multiSelectAllText: {
        fontWeight: 700,
    },
    input: {
        // background:'#fff',
        padding: "11px 14px"
    },
    inputLabel: {
        transform: "translate(14px, 14px) scale(1)",
    },
    fieldExplainer: {
        padding: '5px 10px 5px 10px'
    }
}));

function Create(props) {
    const classes = useStyles();
    const history = useHistory();
    const { permData } = usePerm();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();

    const {
        shortDescriptionStarterText
    } = props;

    const [ isSubmissionBtnBlocked, setIsSubmissionBtnBlocked ]  = React.useState(false);

    const [formResources, setFormResources] = React.useState({
        ticketTypeOptions: [],
        companyOptions: [],
        categoryOptions: [],
        urgencyOptions: [],
        affectedUserOptions:[],
    });
    const [affectedUsersSelectOpenState, setAffectedUsersSelectOpenState] = React.useState(false);
    const [affectedUserList, setAffectedUserList] = React.useState([]);
    const [ticketTypeId, setTicketTypeId] = React.useState(null);
    const ticketTypeHelperText = {
        1: <Trans i18nKey="typeExplainerIncident"></Trans>,
        2: <Trans i18nKey="typeExplainerRequest"></Trans>,
        3: <Trans i18nKey="typeExplainerChange"></Trans>,
      };
      
    const [companyId, setCompanyId] = React.useState(0);
    const [companyInputValue, setCompanyInputValue] = React.useState('');
    const [categoryId, setCategoryId] = React.useState('');
    const [urgencyId, setUrgencyId] = React.useState(null);
    const [ticketId, setTicketId] = React.useState(null);
    const [shortDescription, setShortDescription] = React.useState(shortDescriptionStarterText || '');
    const [description, setDescription] = React.useState('<p></p>');
    const [descriptionValidationState, setDescriptionValidationState] = React.useState(true);

    const [formValidationState, setFormValidationState] = React.useState(false);
    const [submitBtnPostfix, setSubmitBtnPostfix] = React.useState('');

    // dialog window
    const [dialogVisibility, setDialogVisibility] = React.useState(false);
    const [dialogTicketCreationState, setDialogTicketCreationState] = React.useState(true);


    const handleDialogClose = () => {
        setDialogVisibility(false);
        setIsSubmissionBtnBlocked(false);
        if(dialogTicketCreationState) {
            props.history.push("/support/ticket/list");
        }
    };

    const handleTicketCreation = (event) => {
        event.preventDefault();
        if( !descriptionValidationState ) {
            return;
        }
        updateAppLoadingState(true);
        setIsSubmissionBtnBlocked(true);
        axios.post(
            "/api/v1/support/ticket/create",
            serialize({
                ticketId: ticketId,
                ticketTypeId: ticketTypeId,
                debitorId: companyId,
                categoryId: categoryId,
                urgencyId: urgencyId,
                shortDescription: shortDescription,
                description: description,
                affectedUserList: affectedUserList
            })
        ).then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
            } else {
                setDialogTicketCreationState(false);
            }
            setDialogVisibility(true);
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
            setDialogTicketCreationState(false);
            setDialogVisibility(true);
        });
    };

    const handleTicketTypeIdChange = (event) => {
        setTicketTypeId(event.target.value);
    };
    const updateSubmitButtonLabel = (id=null) => {
        id = id ? id : ticketTypeId;
        formResources.ticketTypeOptions.map((item) => {
            if(item.id == id) {
                setSubmitBtnPostfix(item.name);
            }
        });
    }

    const handleCompanyChange = (event, selection) => {
        if( selection && selection.hasOwnProperty('name') ) {
            setCompanyId(selection['id']);
            setCompanyInputValue(selection['name']);
        } else {
            selection = {id: event.target.value}
            setCompanyId(selection['id']);
        }
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/resource/form/ticket/render/get-user-list",
            serialize({
                debitorId: selection['id'],
                method: 1,
            })
        ).then(step1 => {
            updateAppLoadingState(false);
            if (step1.status === 200) {
                setFormResources({
                    ...formResources,
                    affectedUserOptions: step1.data
                });
                setAffectedUserList([]);

                if( step1.data.length ) {
                    axios.post(
                        "/api/v1/resource/form/ticket/render/get-user-list",
                        serialize({
                            debitorId: selection['id'],
                            method: 2,
                        })
                    ).then(step2 => {
                        if (step2.status === 200) {
                            let tmp = [];
                            for (let i in step1.data) {
                                tmp.push({
                                    username: step1.data[i].username,
                                    name: step2.data[step1.data[i].username]
                                });
                            }
                            setFormResources({
                                ...formResources,
                                affectedUserOptions: tmp
                            });
                            setAffectedUserList([]);
                            console.log(formResources.affectedUserOptions);
                        }
                    }).catch(e => {
                        console.log(e);
                    });
                }
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
        });

    };
    const handleCategoryChange = (event) => {
        setCategoryId(event.target.value);
    };
    const handleUrgencyChange = (event) => {
        setUrgencyId(event.target.value);
    };

    const handleShortDescriptionChange = (event) => {
        setShortDescription(event.target.value);
    };
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        variant:'menu',
        getContentAnchorEl: () => null,
        anchorOrigin: {
            vertical: 'bottom',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    const handleAffectedUserSelectOpen = (event) => {
        let selection = typeof event.target !== 'undefined' ? event.target.value : [];
        if( affectedUsersSelectOpenState && selection.indexOf('All')!==-1 ) {
            setAffectedUsersSelectOpenState(false);
        } else {
            setAffectedUsersSelectOpenState(true);
        }
    }
    const handleChangeAffectedUsers = (event, element) => {
        const currentSelection = element.props.value;
        let currentList = event.target.value;
        if(currentList.indexOf('All')!==-1) currentList.splice(currentList.indexOf('All'), 1) // remove all from current list

        const allSelectedPreviously = affectedUserList.indexOf('All') !== -1 ;
        const allSelectedManually = currentList.length === formResources.affectedUserOptions.length;

        if (currentSelection === "All" || allSelectedManually) {
            setAffectedUserList(allSelectedPreviously ? [] : [
                ...new Set([
                    ...['All'],
                    ...[...formResources.affectedUserOptions].map((option)=>option.username)
                ]) 
            ]);
            return;
        } 
        
        setAffectedUserList(currentList);
    };

    function fetchFormResources(onComplete=null) {
        updateAppLoadingState(true);

        axios.post("/api/v1/resource/form/ticket/render/get-data").then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                let resources = result.data;
                setFormResources(resources);
                if( typeof resources.companyOptions[0] !== 'undefined' ) {
                    if( resources.companyOptions.length==1) {
                        setCompanyId(resources.companyOptions[0].id);
                        setCompanyInputValue(resources.companyOptions[0].name);
                    }
                }
                if( typeof onComplete==='function') {
                    onComplete(resources);
                }
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
        });
    }

    useEffect(() => {
        fetchFormResources(function(resources){
            updateAppLoadingState(true);
            axios.post("/api/v1/support/ticket/get-draft").then(result => {
                updateAppLoadingState(false);
                if (result.status === 200) {
                    let draft = result.data;
                    if( !draft.ticketId ) {
                        // no draft - force going through the help page first
                        history.push('/support/get-help');
                    }
                    setTicketId(draft.ticketId);
                    setShortDescription(draft.shortDescription);
                }
            }).catch(e => {
                updateAppLoadingState(false);
            });

        });
    }, []);

    useEffect(() => {
        updateSubmitButtonLabel(ticketTypeId);
        if( formResources.ticketTypeOptions.length && ticketTypeId) {
            updateAppLoadingState(true);
            axios.post(
                "/api/v1/resource/form/ticket/render/get-categories",
                serialize({ticketTypeId: ticketTypeId})
            ).then(result => {
                updateAppLoadingState(false);
                if (result.status === 200) {
                    setFormResources({
                        ...formResources,
                        categoryOptions: result.data
                    });
                }
            }).catch(e => {
                updateAppLoadingState(false);
            });
        }
    }, [ticketTypeId]);

    useEffect(() => {
        if( permData.flags.tc == true || permData.flags.sm == true || permData.flags.psm == true ) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [permData.flags]);

    useEffect(() => {
        setFormValidationState(
            descriptionValidationState
            && shortDescription.trim().length >= 10
        );
    }, [descriptionValidationState,shortDescription]);




    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom={true}>
                {t('createTicket')}
            </Typography>
            <div>
                {(permData.flags.tc==false && permData.flags.sm==false && permData.flags.psm==false) &&
                    <GeneralPermissionDeniedMessage/>
                }
                {(permData.flags.tc == true || permData.flags.sm == true || permData.flags.psm == true) &&
                <form className={classes.form} autoComplete="off" onSubmit={handleTicketCreation}>
                    <Grid container>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Grid container alignItems="center">
                                 <Grid item>
                                    <MuiTextField
                                        select
                                        size="small"
                                        labelId="ticketTypeId"
                                        id="ticketTypeId"
                                        inputProps={{
                                            "data-cy": "cy-createTicket-field-ticketTypeId",
                                        }}
                                        label={t('type')}
                                        value={ticketTypeId}
                                        onChange={handleTicketTypeIdChange}
                                        variant="outlined"
                                        className={classes.dropdown2}
                                        required
                                    >
                                        {formResources.ticketTypeOptions.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(camelCase(item.name))}
                                            </MenuItem>
                                        ))}
                                    </MuiTextField>
                                </Grid>
                                <Grid item align="left">
                                    <FormHelperText className={classes.fieldExplainer}>
                                        {ticketTypeHelperText[ticketTypeId] || ""}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                         </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className={classes.gridItem}>
                            {
                                Object.keys(formResources.companyOptions).length == 1 ? (
                                    <MuiTextField
                                        size="small"
                                        labelId="company"
                                        id="company"
                                        inputProps={{
                                            "data-cy": "cy-createTicket-field-company",
                                        }}
                                        label={t('affectedCompany')}
                                        value={formResources.companyOptions[0].name}
                                        variant="outlined"
                                        readonly
                                        className={classes.dropdown1}
                                        required
                                    />
                                ) : (

                                    Object.keys(formResources.companyOptions).length < 8 ? (
                                        <MuiTextField
                                            select
                                            size="small"
                                            labelId="company"
                                            id="company"
                                            inputProps={{
                                                "data-cy": "cy-createTicket-field-company",
                                            }}
                                            label={t('affectedCompany')}
                                            value={companyId}
                                            onChange={handleCompanyChange}
                                            variant="outlined"
                                            className={classes.dropdown1}
                                            required
                                        >
                                            {formResources.companyOptions.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </MuiTextField>
                                    ) : (
                                        <Autocomplete
                                            disablePortal
                                            openOnFocus
                                            size="small"
                                            labelId="company"
                                            id="company"
                                            options={formResources.companyOptions}
                                            value={companyInputValue}
                                            onChange={handleCompanyChange}
                                            sx={{width: 300}}
                                            required
                                            getOptionLabel={(option) => {
                                                if (option.hasOwnProperty('name')) {
                                                    return option.name;
                                                }
                                                return option;
                                            }}
                                            renderInput={params => {
                                                params.inputProps["data-cy"] = "cy-createTicket-field-company";
                                                return (
                                                    <MuiTextField
                                                        {...params}
                                                            placeholder={t('select')+'..'}
                                                           className={classes.dropdown1}
                                                           variant="outlined"
                                                           label={t('affectedCompany')}
                                                    />
                                                )}
                                            }
                                        />
                                    )
                                )
                            }
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className={classes.gridItem}>
                            <MuiTextField
                                select
                                size="small"
                                labelId="category"
                                id="categoryId"
                                inputProps={{
                                    "data-cy": "cy-createTicket-field-categoryId",
                                }}
                                label={t('category')}
                                value={categoryId}
                                onChange={handleCategoryChange}
                                variant="outlined"
                                className={classes.dropdown2}
                                required
                            >
                                { ! Object.keys(formResources.categoryOptions).length &&
                                    <MenuItem key="none" value="none" disabled>
                                        <ListItemText primary={t('selectTicketType')+'..'} />
                                    </MenuItem>
                                }
                                {Object.keys(formResources.categoryOptions).length && formResources.categoryOptions.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </MuiTextField>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Grid container>
                                <Grid item>
                                    <MuiTextField
                                        select
                                        size="small"
                                        labelId="urgency"
                                        id="urgency"
                                        inputProps={{
                                            "data-cy": "cy-createTicket-field-urgency",
                                        }}
                                        label={t('urgency')}
                                        value={urgencyId}
                                        onChange={handleUrgencyChange}
                                        variant="outlined"
                                        className={classes.dropdown2}
                                        required
                                    >
                                        {formResources.urgencyOptions.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </MuiTextField>
                                </Grid>
                                <Grid item align="left">
                                    <FormHelperText className={classes.fieldExplainer}>
                                        {urgencyId ? [
                                            '',
                                            t('urgencyExplainerHigh'),
                                            t('urgencyExplainerMedium'),
                                            t('urgencyExplainerLow'),
                                        ][urgencyId] : ''}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className={classes.gridItem}>
                            <FormControl variant="outlined" required>
                                <InputLabel
                                    htmlFor="affectedUsers"
                                    className={classes.inputLabel}
                                    ref={inputLabel}
                                >
                                    {t('affectedUsers')}
                                </InputLabel>
                                <Select
                                    className={classes.multiSelect}
                                    labelId="affectedUsers"
                                    id="affectedUsers"
                                    variant="outlined"
                                    multiple
                                    size="small"
                                    value={affectedUserList}
                                    //open={affectedUsersSelectOpenState}
                                    //onClick={handleAffectedUserSelectOpen}
                                    onChange={handleChangeAffectedUsers}
                                    input={<OutlinedInput
                                        labelWidth={labelWidth}
                                        classes={{input: classes.input}}
                                    />}
                                    inputProps={{
                                        "data-cy": "cy-createTicket-field-affectedUsers",
                                    }}
                                    renderValue={(selected) => (selected.indexOf('All') !== -1) ? 'All Users' : selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    { ! Object.keys(formResources.affectedUserOptions).length &&
                                        <MenuItem key="none" value="none" disabled>
                                            <ListItemText primary={t('selectAffectedCompany')+'..'} />
                                        </MenuItem>
                                    }
                                    {Object.keys(formResources.affectedUserOptions).length &&
                                        <MenuItem key="All" value="All">
                                            <Checkbox
                                                checked={formResources.affectedUserOptions.length === affectedUserList.length - 1}/>
                                            <ListItemText primary={t('all2')}
                                                          classes={{primary: classes.multiSelectAllText}}/>
                                        </MenuItem>
                                    }
                                    {formResources.affectedUserOptions.map((item) => (
                                        <MenuItem key={item.username} value={item.username}>
                                            <Checkbox checked={affectedUserList.indexOf(item.username) > -1}/>
                                            <ListItemText primary={item.username} secondary={item.name}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} md={12} lg={6} xl={5} className={classes.gridItem}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                id="title"
                                inputProps={{
                                    "data-cy": "cy-createTicket-field-title",
                                    minLength: 10,
                                }}
                                label={t('shortDescription')}
                                name="title"
                                size="small"
                                value={shortDescription}
                                onChange={handleShortDescriptionChange}
                                validators={[
                                    {
                                        validate: 'minLength',
                                        minLength: 10,
                                        errorMessage: 'Minimum length is 10 characters'
                                    }
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={7} xl={6} className={classes.gridItem}>
                            <RichTextEditor
                                ticketId={ticketId}
                                id="description"
                                defaultValue={'<p></p>'}
                                html={description}
                                setHtml={setDescription}
                                validationState={descriptionValidationState}
                                setValidationState={setDescriptionValidationState}

                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} md={11} lg={7} xl={7}>
                            <FileUploader
                                disabled={false}
                                ticketId={ticketId}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Button
                                disabled={!formValidationState || isSubmissionBtnBlocked}
                                data-cy="cy-createTicket-submit-btn"
                                variant="contained" color="secondary" margin="normal" startIcon={<DoneIcon/>}
                                    type="submit">
                                {t('create')} {t(camelCase(submitBtnPostfix))}
                            </Button>
                        </Grid>
                    </Grid>

                </form>
                }
                <Dialog
                    open={dialogVisibility}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('createANewTicket')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="text.primary" id="alert-dialog-description">
                            { dialogTicketCreationState ? t('yourTicketHasBeenSuccessfullyCreated')+'.' : t('unableToCreateTicket')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-cy="cy-createTicket-dialog-ok-btn"
                            onClick={handleDialogClose} color="primary" autoFocus>
                            {t('ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}

export default Create;