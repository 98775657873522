import React, { useState, useEffect } from "react";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useTranslation } from "react-i18next";

import UpdateProfileDialog from "./UpdateProfileDialog/UpdateProfileDialog";
import ResponseToast from "components/Combinations/ResponseToast/ResponseToast";
import { useAppLoadingState } from "context/appLoadingState";

function UpdateProfilePopup({ onComplete }) {
  const { t } = useTranslation();
  const { updateAppLoadingState } = useAppLoadingState();

  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profileUpdateSuccessState, setProfileUpdateSuccessState] =
    useState(null);
  const [failureMessage, setFailureMessage] = useState("");
  const autoHideDuration = 2000;

  useEffect(() => {
    const nextDialogDate = getDialogDate();
    if (Date.now() > nextDialogDate) {
      fetchProfileAndCheckIfComplete();
    } else {
      onComplete();
    }
  }, []);

  const setNewDialogDate = () => {
    const futureDate = new Date();
    futureDate.setMonth(futureDate.getMonth() + 3);
    window.localStorage.setItem(
      "updateProfileDisplayDate",
      futureDate.getTime().toString()
    );
  };

  const getDialogDate = () => {
    const storedDateStr = window.localStorage.getItem(
      "updateProfileDisplayDate"
    );
    if (storedDateStr) {
      return new Date(parseInt(storedDateStr, 10));
    } else {
      setNewDialogDate();
      return new Date(0); // Epoch time, ensures it's in the past to guarantee fetching the profile
    }
  };

  const fetchProfileAndCheckIfComplete = () => {
    axios
      .post("/api/v1/profile/fetch")
      .then((result) => {
        if (result.status === 200) {
          const currentProfile = result.data;
          const isCurrentProfileComplete =
            currentProfile?.mail &&
            currentProfile?.firstName &&
            currentProfile?.lastName;
          setProfile(currentProfile);

          if (!isCurrentProfileComplete) {
            setOpenProfileDialog(true);
          } else {
            handleClose();
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClose = () => {
    setOpenProfileDialog(false);
    setNewDialogDate();
    if (typeof onComplete === "function") {
      onComplete();
    }
  };

  const updateProfile = (profile) => {
    updateAppLoadingState(true);

    axios
      .post(
        "/api/v1/profile/edit",
        serialize({
          firstName: profile.firstName,
          lastName: profile.lastName,
          telephoneNumber: profile.telephoneNumber,
          mail: profile.mail,
          totp: profile.totp,
        })
      )
      .then((result) => {
        updateAppLoadingState(false);
        if (result.status === 200) {
          if (result.data.success) {
            setProfileUpdateSuccessState(true);
            setTimeout(handleClose, autoHideDuration); // Delay time matches autoHideDuration
          } else {
            setProfileUpdateSuccessState(false);
            if (result.data.errorCode == 555) {
              setFailureMessage(result.data.message);
            } else {
              setFailureMessage(t("failedToUpdateProfilePleaseTryAgainLater"));
            }
          }
        }
      })
      .catch((e) => {
        updateAppLoadingState(false);
        setProfileUpdateSuccessState(false);
        setFailureMessage(t("failedToUpdateProfilePleaseTryAgainLater"));
      });
  };

  return (
    openProfileDialog && (
      <>
        <UpdateProfileDialog
          open={openProfileDialog}
          onClose={handleClose}
          profile={profile}
          updateProfile={updateProfile}
        />
        <ResponseToast
          key="profileEditResponseToast"
          autoHideDuration={autoHideDuration}
          responseStatus={profileUpdateSuccessState}
          successMessage={t("yourProfileWasUpdatedSuccessfully")}
          failureMessage={failureMessage}
          closeHandler={() => setProfileUpdateSuccessState(null)}
        />
      </>
    )
  );
}

export default UpdateProfilePopup;
