import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Collapse from "@mui/material/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

function UpdateProfileDialog({ open, onClose, profile, updateProfile }) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState(profile?.firstName || "");
  const [lastName, setLastName] = useState(profile?.lastName || "");
  const [telephoneNumber, setTelephoneNumber] = useState(
    profile?.telephone || ""
  );
  const [mail, setMail] = useState(profile?.mail || "");
  const [totp, setTotp] = useState("");

  useEffect(() => {
    if (profile) {
      setFirstName(profile.firstName);
      setLastName(profile.lastName);
      setTelephoneNumber(profile.telephone);
      setMail(profile.mail);
    }
  }, [profile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile({ firstName, lastName, telephoneNumber, mail, totp });
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event, reason);
          }
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        }}
      >
        <DialogTitle>
          <strong>{t("completeYourProfile")}</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("completeYourProfileInfo")}</DialogContentText>
          <ValidatorForm autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              variant="outlined"
              onChange={(e) => setFirstName(e.target.value)}
              margin="normal"
              size="small"
            />
            <TextField
              fullWidth
              label="Last Name"
              value={lastName}
              variant="outlined"
              onChange={(e) => setLastName(e.target.value)}
              margin="normal"
              size="small"
            />
            <TextField
              fullWidth
              label="Telephone"
              value={telephoneNumber}
              variant="outlined"
              onChange={(e) => setTelephoneNumber(e.target.value)}
              margin="normal"
              size="small"
            />
            <TextValidator
              fullWidth
              label="Email"
              name="email"
              value={mail}
              variant="outlined"
              onChange={(e) => {
                setMail(e.target.value);
              }}
              validators={["isEmail"]}
              errorMessages={[t("enterValidEmail")]}
              margin="normal"
              size="small"
            />
            <Collapse in={mail !== profile.mail}>
              <TextField
                margin="normal"
                fullWidth
                required={mail !== profile.mail}
                disabled={mail === profile.mail}
                label="TOTP"
                type="text"
                variant="outlined"
                size="small"
                value={totp}
                inputProps={{
                  maxLength: 6,
                }}
                onChange={(e) => {
                  const newTotp = e.target.value.replace(/\D/g, ""); //ignore everything but numbers
                  setTotp(newTotp);
                }}
              />
              <DialogContentText>
                {t("emailChangeRequiresTotp")}
              </DialogContentText>
            </Collapse>

            <DialogActions>
              <Button onClick={onClose} variant="text" color="primary">
                {t("close")}
              </Button>
              <Button type="submit" variant="contained" color="secondary">
                {t("saveChanges")}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UpdateProfileDialog;
