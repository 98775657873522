import React from "react";
import { useTranslation } from "react-i18next";
import "@fontsource/barlow-condensed/700.css";
import { Container, Toolbar, Typography } from "@material-ui/core";
import TimelineGraph from "./TimelineGraph/TimelineGraph";
import DataProtectionInfo from "./DataProtectionInfo/DataProtectionInfo";

function RequestCertification() {
  const { t, i18n } = useTranslation();

  const urlGerman =
    "https://eu.services.docusign.net/webforms-ux/v1.0/forms/ca4cb25b4d89df105f62845cb3addece";
  const urlEnglish =
    "https://eu.services.docusign.net/webforms-ux/v1.0/forms/56e5b7e7616ec42c79e7192b864b2e26";
  const docusingUrl = i18n.language === "de" ? urlGerman : urlEnglish;

  return (
    <div style={{ overflow: "hidden" }}>
      <Container
        component="main"
        maxWidth="lg"
        sx={{ my: 2, overflow: "hidden" }}
      >
        <Toolbar />
        <Typography
          variant="h4"
          style={{
            fontWeight: 700,
            fontFamily: "Barlow Condensed, sans-serif",
            letterSpacing: -1,
            textTransform: "uppercase",
            marginTop: 16,
            fontSize: "2.125rem",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          {t("requestCertificationTitle")}
        </Typography>

        <TimelineGraph />
      </Container>
      <div style={{ width: "100vw", height: "800px", overflow: "hidden" }}>
        <iframe
          src={docusingUrl}
          style={{
            border: "none",
            overflow: "hidden",
            width: "100vw",
            height: "100%",
          }}
          title="Certification-form"
        />
      </div>
      <Container maxWidth="lg" sx={{ py: 2, overflow: "hidden" }}>
        <DataProtectionInfo />
      </Container>
    </div>
  );
}

export default RequestCertification;
