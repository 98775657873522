import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "@fontsource/montserrat/500.css";
import TimelineSvg from "assets/images/timeline_three_steps.svg";

const useStyles = makeStyles((theme) => ({
  timelineImage: {
    width: "100%",
    height: "auto",
  },
  stepsText: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
  },
  stepsTextB: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: 1.2,
    display: "block",
  },
  stepsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "-54px",
    marginLeft: "4%", // Same as dataProtectionContainer
  },
  stepItem: {
    flex: 1,
    "&:not(:first-child)": {
      marginLeft: "6px", // Half the margin of the dataProtectionText
    },
    "&:not(:last-child)": {
      marginRight: "6px", // Half the margin of the dataProtectionText
    },
  },
}));

export default function TimelineGraph() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.timelineContainer} marginBottom={2}>
      <Grid item xs={9}>
        <Typography variant="body2">
          <Trans i18nKey="requestCertificationInfo" />
        </Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={9}>
          <img
            src={TimelineSvg}
            alt="Timeline showing three steps"
            className={classes.timelineImage}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            className={classes.stepsText}
            gutterBottom
          >
            <Trans i18nKey="requestCertificationInfoStep4" />
          </Typography>
          <Typography
            variant="subcaption"
            color="textSecondary"
            className={classes.stepsTextB}
          >
            <Trans i18nKey="requestCertificationInfoStep4b" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8} className={classes.stepsContainer}>
          {[
            "requestCertificationInfoStep1",
            "requestCertificationInfoStep2",
            "requestCertificationInfoStep3",
          ].map((stepKey, index) => (
            <Typography
              key={index}
              className={`${classes.stepsText} ${classes.stepItem}`}
              component="div"
              variant="body2"
            >
              {t(stepKey)}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
