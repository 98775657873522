import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { serialize } from "object-to-formdata";
import { format } from "date-fns-tz";
import { parseISO } from "date-fns";
import { debounceSearchRender } from "mui-datatables";

import { Stack, Tooltip, Chip, Fab, Typography } from "@mui/material"; // TODO:consistent imports
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@material-ui/icons/Clear";

import { usePerm } from "context/permission";
import GeneralPermissionDeniedMessage from "components/Shared/StaticContainerMessage/GeneralPermissionDeniedMessage/GeneralPermissionDeniedMessage";
import ExtendedDataTable from "components/Combinations/ExtendedDataTable/ExtendedDataTable";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  header: {
    flex: 1,
    paddingBottom: 0,
  },
  resetButton: {
    textTransform: "none",
  },
}));

const getOptionsNamesByIds = (ids, options) => {
  if (options.length === 0) {
    return null;
  }
  return ids?.map((id) => options?.find((option) => option.id === id)?.name);
};

const haveFiltersChanged = (defaultFilters, currentFilters) => {
  if (!defaultFilters || !currentFilters) {
    return true;
  }

  const keysToCheck = Object.keys(defaultFilters);

  for (const key of keysToCheck) {
    const currentArray = currentFilters[key]?.slice()?.sort() || [];
    const defaultArray = defaultFilters[key]?.slice()?.sort() || [];
    if (currentArray.length !== defaultArray.length) {
      return true;
    }

    for (let i = 0; i < currentArray.length; i++) {
      if (currentArray[i] !== defaultArray[i]) {
        return true;
      }
    }
  }
  return false;
};

function List(props) {
  const classes = useStyles();
  const history = useHistory();
  const { permData } = usePerm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const confirmFilters = false;
  const storageKey = "ticketListFiltersCustomerPortalPaginated";

  const [count, setCount] = useState(null);
  const [ticketList, setTicketList] = useState([]);

  const [formResources, setFormResources] = useState({
    statusOptions: [],
    ticketTypeOptions: [],
    urgencyOptions: [],
    companyOptions: [],
  });

  const [ticketOwnerOptions, setTicketOwnerOptions] = useState([]);
  const [isLoadingTicketOwnerOptions, setIsLoadingTicketOwnerOptions] =
    useState(false);

  const defaultSortBy = { columnName: "lastUpdateDate", direction: "desc" };
  const defaultFilterList = {
    statusId: [1, 2, 3, 5], // "1-Pending", "2-Active", "3-Resolved", "5-Postponed"
    ticketTypeId: [],
    urgencyId: [],
    companyId: [],
    ownerUsername: [],
  };

  const [serverFetchFilterList, setServerFetchFilterList] = useState(() => {
    const defaultData = {
      ...defaultFilterList,
      sortBy: [defaultSortBy],
      limit: 10, // rows per apge default
      search: "",
      page: 1,
    };
    try {
      const savedData = window.localStorage.getItem(storageKey);
      return savedData ? JSON.parse(savedData) : defaultData; // Use saved value or default to an empty string
    } catch (error) {
      return defaultData;
    }
  });

  const sortByMui = {
    name:
      serverFetchFilterList.sortBy?.[0].columnName === "internalPriorityId"
        ? "internalPriority"
        : serverFetchFilterList.sortBy?.[0].columnName,
    direction: serverFetchFilterList.sortBy?.[0].direction,
  };

  const filtersChanged = haveFiltersChanged(
    defaultFilterList,
    serverFetchFilterList
  );

  const sortsChanged =
    defaultSortBy.direction !== serverFetchFilterList.sortBy[0].direction ||
    defaultSortBy.columnName !== serverFetchFilterList.sortBy[0].columnName;

  const showResetFilterButton = sortsChanged || filtersChanged;

  const columns = [
    {
      label: t("urgency"),
      name: "urgency.name",
      options: {
        filter: true,
        filterList:
          formResources?.fetched &&
          getOptionsNamesByIds(
            serverFetchFilterList.urgencyId,
            formResources.urgencyOptions
          ),
        filterOptions: {
          names: formResources.urgencyOptions.map((entry) => entry.name),
          fullWidth: true,
        },
        sort: false,
        sortDescFirst: true,
        customHeadLabelRender: () => " ",
        customBodyRenderLite: (dataIndex) => {
          const urgencyColors = {
            Low: "info",
            Medium: "warning",
            High: "error",
          };
          const urgencyIcons = {
            Low: KeyboardArrowDownIcon,
            Medium: DragHandleIcon,
            High: KeyboardArrowUpIcon,
          };
          const urgencyValue = ticketList[dataIndex]?.urgency?.name;
          const iconColor = urgencyColors[urgencyValue] || "info";
          const UrgencyIcon = urgencyIcons[urgencyValue] || DragHandleIcon;

          return (
            <Tooltip title={t("urgency") + ": " + urgencyValue}>
              <UrgencyIcon size="small" color={iconColor} />
            </Tooltip>
          );
        },
      },
    },
    {
      name: "ticketId",
      label: "Ticket ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "ticketType",
      label: t("type"),
      options: {
        filter: true,
        filterList:
          formResources.fetched &&
          getOptionsNamesByIds(
            serverFetchFilterList.ticketTypeId,
            formResources.ticketTypeOptions
          ),
        sort: true,
        filterOptions: {
          names: formResources.ticketTypeOptions.map((entry) => entry.name),
          fullWidth: true,
        },
      },
    },
    {
      name: "shortDescription",
      label: t("shortDescription"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "createDate",
      label: t("createDate"),
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRenderLite: (dataIndex) => {
          const value = ticketList[dataIndex].createDate;
          return (
            <div>
              {value ? format(parseISO(value), "dd.MM.yyyy HH:mm:ss") : "-"}
            </div>
          );
        },
      },
    },
    {
      name: "ownerName",
      label: t("ticketOwner"),
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const id = ticketList[dataIndex].ownerUsername;
          const name = ticketList[dataIndex]?.ownerName || "";

          return (
            <>
              <Typography variant="inherit">
                {name?.trim() !== id ? name : ""}
              </Typography>
              <Typography variant="inherit" color="text.secondary">
                {id || ""}
              </Typography>
            </>
          );
        },
      },
    },
    {
      label: t("ticketOwner"),
      name: "ownerUsername",
      options: {
        display: "excluded",
        sort: false,
        filter: true,
        filterList:
          formResources.fetched && serverFetchFilterList.ownerUsername,
        customFilterListOptions: {
          render: (v) => `${t("ticketOwner")}: ${v}`,
        },
        filterOptions: {
          names: ticketOwnerOptions.map((opt) => opt.username),
          fullWidth: true,
          renderValue: (username) => {
            const user = ticketOwnerOptions.find(
              (opt) => opt.username === username
            );
            const name = user?.name ? `(${user.name})` : "";
            return `${username} ${name}`;
          },
        },
      },
    },
    {
      name: "lastUpdateDate",
      label: t("lastModified"),
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRenderLite: (dataIndex) => {
          const value = ticketList[dataIndex].lastUpdateDate;
          return (
            <div>
              {value ? format(parseISO(value), "dd.MM.yyyy HH:mm:ss") : "-"}
            </div>
          );
        },
      },
    },
    {
      name: "description",
      label: t("description"),
      options: {
        display: "excluded",
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: t("status"),
      options: {
        sort: true,
        filter: true,
        filterList:
          formResources.fetched &&
          getOptionsNamesByIds(
            serverFetchFilterList.statusId,
            formResources.statusOptions
          ),
        filterOptions: {
          names: formResources.statusOptions?.map((entry) => entry.name),
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex) => {
          const value = ticketList[dataIndex].status;

          let styleMap = {
            Active: { color: "success", variant: "filled" },
            Pending: { color: "primary", variant: "filled" },
            Postponed: { color: "primary", variant: "outlined" },
            Resolved: { color: "primary", variant: "outlined" },
            Closed: { color: "success", variant: "outlined" },
          };
          return (
            <Chip
              label={value}
              size="small"
              color={styleMap[value]?.color || "error"}
              variant={styleMap[value]?.variant || "outlined"}
            />
          );
        },
      },
    },
    {
      name: "priority",
      label: t("priority"),
      options: {
        display: "excluded",
        filter: false,
        sort: true,
      },
    },

    {
      label: t("affectedCompany"),
      name: "company",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: t("affectedCompany") + " (ID)",
      name: "companyId",
      options: {
        display: "excluded",
        sort: false,
        filter: true,
        filterList: formResources.fetched && serverFetchFilterList.companyId,
        customFilterListOptions: {
          render: (v) => `${t("affectedCompany")}: ${v}`,
        },
        filterOptions: {
          names: formResources.companyOptions.map((opt) => opt.id),
          fullWidth: true,
          renderValue: (val) => {
            const company = formResources.companyOptions.find(
              (opt) => opt.id === val
            );
            const companyName = company ? company.name : "";
            return `${companyName} (${val})`;
          },
        },
      },
    },
  ];

  // fetch ticket data on mount
  useEffect(() => {
    if (
      formResources?.fetched &&
      (permData.flags.tc || permData.flags.sm || permData.flags.psm)
    ) {
      updateTicketData(serverFetchFilterList);
    }
  }, [formResources?.fetched, permData.flags]);

  // fetch form resources on mount
  useEffect(() => {
    const fetchFormResources = () => {
      axios
        .post("/api/v1/resource/form/ticket/render/get-data")
        .then((result) => {
          if (result.status === 200) {
            let resources = result.data;
            setFormResources({ ...resources, fetched: true });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    fetchFormResources();
  }, []);

  // update local storage on filter change
  useEffect(() => {
    window.localStorage.setItem(
      storageKey,
      JSON.stringify(serverFetchFilterList)
    );
  }, [serverFetchFilterList]);

  // fetch ticket owner list for filter dropdown
  useEffect(() => {
    setIsLoadingTicketOwnerOptions(true);

    axios
      .post("/api/v1/resource/form/ticket/render/get-owner-list")
      .then((result) => {
        if (result.status === 200) {
          setTicketOwnerOptions(result.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoadingTicketOwnerOptions(false);
      });
  }, []);

  const updateServerFetchFilterList = (filterList) => {
    const filterListNames = {};

    columns.forEach((column, index) => {
      filterListNames[column.name] = filterList[index] || [];
    });

    let newServerFetchFilterList = {};

    Object.keys(filterListNames).forEach(
      (changedColumn, changedColumnIndex) => {
        if (changedColumn === "urgency.name") {
          const updatedUrgencyNames = filterList[changedColumnIndex];
          const updatedUrgencyIds = formResources.urgencyOptions
            .filter((type) => updatedUrgencyNames.includes(type.name))
            .map((type) => type.id);
          newServerFetchFilterList = {
            ...newServerFetchFilterList,
            urgencyId: updatedUrgencyIds,
          };
        }

        if (changedColumn === "ticketType") {
          const updatedTypeNames = filterList[changedColumnIndex];

          const updatedTypeIds = formResources.ticketTypeOptions
            .filter((type) => updatedTypeNames.includes(type.name))
            .map((type) => type.id);

          newServerFetchFilterList = {
            ...newServerFetchFilterList,
            ticketTypeId: updatedTypeIds,
          };
        }
        if (changedColumn === "status") {
          const updatedStatusNames = filterList[changedColumnIndex];

          const updatedStatusIds = formResources.statusOptions
            .filter((status) => updatedStatusNames.includes(status.name))
            .map((status) => status.id);

          newServerFetchFilterList = {
            ...newServerFetchFilterList,
            statusId: updatedStatusIds,
          };
        }
        if (changedColumn === "companyId") {
          const updatedCompanyIds = filterList[changedColumnIndex];
          newServerFetchFilterList = {
            ...newServerFetchFilterList,
            companyId: updatedCompanyIds,
          };
        }
        if (changedColumn === "ownerUsername") {
          const updatedOwnerusernames = filterList[changedColumnIndex];

          newServerFetchFilterList = {
            ...newServerFetchFilterList,
            ownerUsername: updatedOwnerusernames,
          };
        }
      }
    );

    const newFilterList = {
      ...serverFetchFilterList,
      ...newServerFetchFilterList,
      page: 1,
    };

    updateTicketData(newFilterList);
  };

  const fetchTickets = (filters) => {
    return axios.post(
      "/api/v1/support/ticket/list",
      serialize(filters, { indices: true })
    );
  };

  const updateTicketData = (filterList) => {
    // TODO: test permissions, it can be all 0
    if (permData.flags.tc || permData.flags.sm || permData.flags.psm) {
      setIsLoading(true);
      fetchTickets(filterList)
        .then((result) => {
          setIsLoading(false);
          if (result.status === 200) {
            setTicketList(result.data.list);
            setServerFetchFilterList(filterList);
            setCount(result.data?.summary?.totalVisibleCount); //TODO
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  };

  const resetFiltersAndSorts = () => {
    const newFilterList = {
      ...serverFetchFilterList,
      ...defaultFilterList,
      sortBy: [defaultSortBy],
      page: 1,
    };
    updateTicketData(newFilterList);
  };

  const onFilterChange = (
    _changedColumn,
    updatedFilterList,
    type,
    _changedColumnIndex
  ) => {
    if (type === "reset") {
      resetFiltersAndSorts();
    }
    if (type === "chip") {
      updateServerFetchFilterList(updatedFilterList);
    }

    const filterMenuTypes = [
      "checkbox",
      "dropdown",
      "multiselect",
      "textField",
      "custom",
    ];
    const isFilterMenuFilter = filterMenuTypes.includes(type);

    if (isFilterMenuFilter && !confirmFilters) {
      updateServerFetchFilterList(updatedFilterList);
    }
  };

  const onChangePage = (page) => {
    const newFilterList = {
      ...serverFetchFilterList,
      page: page + 1,
    };
    updateTicketData(newFilterList);
  };

  const onChangeRowsPerPage = (rowsPerPage) => {
    const newFilterList = {
      ...serverFetchFilterList,
      limit: rowsPerPage,
      page: 1,
    };
    updateTicketData(newFilterList);
  };

  const onColumnSortChange = (name, direction) => {
    const sortBy = [
      {
        columnName: name === "internalPriority" ? "internalPriorityId" : name,
        direction: direction,
      },
    ];

    const newFilterList = {
      ...serverFetchFilterList,
      sortBy,
      page: 1,
    };
    updateTicketData(newFilterList);
  };

  const onSearchChange = (string) => {
    const newFilterList = {
      ...serverFetchFilterList,
      search: string?.trim(),
    };
    updateTicketData(newFilterList);
  };

  const renderResetButton = (
    <>
      <Button
        edge="end"
        size="small"
        startIcon={<ClearIcon />}
        onClick={resetFiltersAndSorts}
        className={classes.resetButton}
      >
        {t("resetFiltersAndSorts")}
      </Button>
    </>
  );

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h4" className={classes.header}>
          {t("myTickets")}
        </Typography>

        <Tooltip title={t("createANewTicket")}>
          <Fab
            color="primary"
            onClick={() => history.push("/support/get-help")}
            size="small"
            sx={{ mr: 2 }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Stack>

      {permData.flags.tc === false &&
        permData.flags.sm === false &&
        permData.flags.psm === false && <GeneralPermissionDeniedMessage />}
      {(permData.flags.tc === true ||
        permData.flags.sm === true ||
        permData.flags.psm === true) && (
        <div style={{ position: "relative" }}>
          {isLoading && <LoadingSpinner />}
          <ExtendedDataTable
            isOpen={true}
            isDenseTable={false}
            title={showResetFilterButton && renderResetButton}
            columns={columns}
            highlightExpandedRow={false}
            options={{
              serverSide: true,
              count,
              confirmFilters,
              customSearchRender: debounceSearchRender(1000),
              onChangePage,
              onChangeRowsPerPage,
              onColumnSortChange,
              onFilterChange,
              onSearchChange,
              page: serverFetchFilterList.page - 1,
              responsive: "vertical",
              rowsPerPage: serverFetchFilterList.limit,
              searchText: serverFetchFilterList.search,
              sortOrder: sortByMui,
              onRowClick: (row) => {
                const idColumnIndex = 1;
                const selectedTicketId = row[idColumnIndex];
                props.history.push(
                  `/support/ticket/details/${selectedTicketId}`
                );
              },
            }}
            data={ticketList}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default List;
