import axios from "axios";
import {serialize} from "object-to-formdata";
import JSZip from "jszip";


const http = axios.create({
    baseURL: '/api/v1/support/ticket/attachment',

});

class FileUploadService {

    http() {
        return http;
    }

    /**
     * Upload an Attachment
     */
    upload(ticketId, file, onUploadProgress) {
        let formData = new FormData();

        formData.append('ticketId', ticketId);
        formData.append('file', file);

        return http.post("/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    /**
     * Delete an Attachment
     */
    delete(ticketId, id, onComplete) {
        return http.post("/delete", serialize({
            ticketId: ticketId,
            id: id
        }))
        .then((response) => {
            if( typeof onComplete === 'function') {
                onComplete(response);
            }
        });
    }

    /**
     * Download an Attachment
     */
    download(ticketId, file) {
        return http.post(
            "/download",
            serialize({
                ticketId: ticketId,
                id: file.id
            }),
            {
                responseType: 'blob',
            })
            .then((response) => {
                const blob = new Blob([response.data], {type:  response.headers['content-type'] });
                // for IE11
                if (typeof window.navigator.msSaveOrOpenBlob === 'function') {
                    window.navigator.msSaveOrOpenBlob(blob);
                }
                // Any other browser
                else {
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', file.name);
                    link.click();
                    window.URL.revokeObjectURL(downloadUrl);
                }
            });
    }
    /**
   * Get Blob
   */
    getBlob(ticketId, file) {
        return http
        .post(
            "/download",
            serialize({
            ticketId: ticketId,
            id: file.id,
            }),
            {
            responseType: "blob",
            }
        )
        .then(
            (response) =>
            new Blob([response.data], {
                type: response.headers["content-type"],
            })
        );
    }

    /**
     * Download all attachments as zip
     */
    downloadAll(ticketId, files) {
        const zip = new JSZip();
        const zipFilename = "attachments.zip";

        // Iterate through each attachment and add it to the zip file
        const fetchPromises = files.map((file) => {
        return this.getBlob(ticketId, file)
            .then((blob) => zip.file(file.name, blob))
            .catch((error) => console.error(error));
        });

        // Generate the zip file and initiate the download
        return Promise.all(fetchPromises)
        .then(() => zip.generateAsync({ type: "blob" }))
        .then((content) => {
            const zipUrl = window.URL.createObjectURL(content);
            const link = document.createElement("a");
            link.href = zipUrl;
            link.setAttribute("download", zipFilename);
            link.click();
            window.URL.revokeObjectURL(zipUrl);
        });
    }
    /**
     * List Attachments
     */
    list(ticketId) {
        return http.post(
            '/list',
            serialize({
                ticketId: ticketId,
            })
        );
    }
}

export default new FileUploadService();