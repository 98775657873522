import React, { useState, useEffect} from "react";
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import Download from "@mui/icons-material/Download";

import FileUploadService from '../../../../../../services/ticket/attachment/http.service';
import AlertDialog from "../../../../../Shared/Communication/AlertDialog";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '100%',
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'block'
    },
    attachmentLink: {
        cursor:'pointer'
    },
    btnUpload: {
        position: 'absolute !important',
        right: '10px'
    },
    standardUploadBtn: {
        background:'#fff'
    },
    fileName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        maxWidth: '300px',
        verticalAlign: 'middle'
    },
    uploadMessage: {
        fontWeight: 'bold',
        marginTop: '20px !important',
        color: '#63A84E',
    },
    uploadMessageError: {
        fontWeight: 'bold',
        marginTop: '20px !important',
        color: '#DA4148'
    },
    uploadLimitNoticeRow: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    }
}));

// maxFileUploads= 20;
const maxFileUploadSizeMB= 10;

function UploadFile(props) {
    const {
        disabled,
        displayFormat,
        uploadButtonLabel,
        onUploadComplete,
        uploadButtonProperties
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();
    const [isDownloadingAll, setIsDownloadingAll] = useState(false);
    const [uploadData, setUploadData] = useState({
        currentDeletedFileIds: [], // selectedFileIdsDeletion
        message: '',
        isError: false,
        uploadedFilesData: [],
        activeBatchUploads: 0, // Track number of active upload batches
        selectedFiles: [], // selectedFilesUpload
        progressArray: [], // selectedFilesUploadProgress

    });
    const isUploading = uploadData?.selectedFiles.length > 0;
    const numberOfUploadedFiles = uploadData?.uploadedFilesData?.length;


    /**
     * load the list of attachment of a specific ticket
     */
    useEffect(() => {
        if ( props.ticketId !== null ) {
            FileUploadService.list(props.ticketId).then((response) => {
                setUploadData((prevState) => ({
                    ...prevState,
                    uploadedFilesData: response.data,
                }));
            });
        }
    }, [props.ticketId]);

    /**
     * Handling an attachment delete
     */
    function handleFileDelete(file, event) {

        if (!uploadData.currentDeletedFileIds.includes(file.id)) {
            setUploadData((prevState) => ({
                ...prevState,
                currentDeletedFileIds: [...prevState.currentDeletedFileIds, file.id],
              }));

            FileUploadService.delete(props.ticketId, file.id, function () {
                return FileUploadService.list(props.ticketId).then((files) => {
                    setUploadData((prevState) => ({
                        ...prevState,
                        currentDeletedFileIds: prevState.currentDeletedFileIds.filter(
                            (id) => id !== file.id
                        ),
                        uploadedFilesData: files.data,
                    }));
                });
            })
        }
    }

    /**
     * Handling an attachment download
     */
    function handleAttachmentDownload(file, event) {
        FileUploadService.download(
            props.ticketId,
            file
        );
    }

    /**
     * Handling an attachment upload
     */
    async function handleFileUpload(event) {
        const newFiles = Array.from(event.target.files)
        const filesBeingUploaded = uploadData.selectedFiles || [];
        const files = [...filesBeingUploaded, ...newFiles];

        setUploadData((prevState) => ({
            ...prevState,
            isError: false,
            message: "",
            selectedFiles: files,
            progressArray: [
                ...prevState.progressArray,
                ...new Array(newFiles.length).fill(0),
            ],
            activeBatchUploads: prevState.activeBatchUploads + 1,
        }));

        try {
            const uploadPromises = newFiles.map(async (file, index) => {
                const actualIndex = filesBeingUploaded.length + index;
                const response = await FileUploadService.upload(
                props.ticketId,
                file,
                (event) => {
                    setUploadData((prevState) => ({
                    ...prevState,
                    progressArray: prevState.progressArray.map((value, i) =>
                        i === actualIndex
                        ? Math.round((100 * event.loaded) / event.total)
                        : value
                    ),
                    }));
                }
                );
            return response.data;
        });


        // Wait for all uploads to complete
        const uploadResponses = await Promise.all(uploadPromises);

        const isError = uploadResponses.some(
            (response) => response.maxFilesReached || response.isInfected
        );

        const responseMessages = uploadResponses
            .map((response) => response.message)
            .filter((message) => message) // This removes empty messages
            .join("\n");

        setUploadData((prevState) => ({
            ...prevState,
            message: responseMessages,
            isError,
        }));
        } catch (error) {
        setUploadData((prevState) => ({
            ...prevState,
            message: t("couldNotUploadFile"),
            isError: true,
        }));
        } finally {
        // Decrement activeBatchUploads and trigger handleUploadCompletion if all batches are completed
        setUploadData((prevState) => {
            const newActiveBatchUploads = prevState.activeBatchUploads - 1;

            // handle upload completion
            if (newActiveBatchUploads === 0) {
                FileUploadService.list(props.ticketId).then((response) => {
                    setUploadData((prevState) => ({
                        ...prevState,
                        uploadedFilesData: response.data ?? [],
                        progressArray: [],
                        selectedFiles: [],
                    }));
                });
            
                if (typeof onUploadComplete === "function") {
                    onUploadComplete();
                }
            }

            return {
            ...prevState,
            activeBatchUploads: newActiveBatchUploads,
            };
        });
        }
    }

    async function handleFileDownloadAll() {
        setIsDownloadingAll(true);
        await FileUploadService.downloadAll(props.ticketId, uploadData.uploadedFilesData);
        setIsDownloadingAll(false);
    }

    return (
        <React.Fragment>
            {/* TODO: ticket create file uploader */}
            {!displayFormat || displayFormat!='compact' ?
                (
                    <Grid container className={classes.root}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box mb={1}>
                                        <Grid container>
                                            <Grid item justify="flex-start">
                                                <Box>
                                                    <Typography variant="subtitle1">
                                                        Attachments
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item justify="flex-start">
                                                <Box pl={3}>
                                                    {disabled==false &&
                                                        <label htmlFor="btn-upload">
                                                            <input
                                                                id="btn-upload"
                                                                name="btn-upload"
                                                                style={{display: 'none'}}
                                                                type="file"
                                                                multiple
                                                                data-cy="cy-fileUploader-hidden-upload-btn"
                                                                onChange={handleFileUpload}/>
                                                            <Button
                                                                className={classes.standardUploadBtn}
                                                                variant="outlined"
                                                                size="small"
                                                                component="span"
                                                                startIcon={<AttachFileIcon/>}
                                                                data-cy="cy-fileUploader-addAttachment-btn"
                                                            >
                                                                {uploadButtonLabel ? uploadButtonLabel : 'Add Attachment'}
                                                            </Button>
                                                        </label>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12} lg={7}>
                                    <TableContainer component={Paper} variant="outlined">
                                        <Table>
                                            <TableBody>
                                                {uploadData.uploadedFilesData &&
                                                uploadData.uploadedFilesData.map((file, index) => (
                                                    <TableRow key={file.id}>
                                                        <TableCell component="th" scope="row" width="60%">
                                                            <Link
                                                                data-cy="cy-fileUploader-uploadedFiles-item"
                                                                component="span" className={classes.attachmentLink} onClick={(event) => handleAttachmentDownload(file, event)}>
                                                                {file.name}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="right" width="40%">
                                                            {
                                                                disabled == false &&
                                                                <IconButton
                                                                    data-cy="cy-fileUploader-uploadedFiles-item-delete-btn"
                                                                    aria-label="delete" size="small"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        if (uploadData.currentDeletedFileIds.includes(file.id)) return;
                                                                        handleFileDelete(file, event);
                                                                    }}
                                                                >
                                                                    {uploadData.currentDeletedFileIds.includes(file.id)
                                                                        ? <CircularProgress size={16}/>
                                                                        : <DeleteIcon fontSize="inherit"/>
                                                                    }
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}

                                                {uploadData.selectedFiles.map((selectedFile, index) => ( 
                                                        <TableRow key={index}>
                                                        <TableCell component="th" scope="row" width="60%">
                                                            <Link component="span">
                                                                    {selectedFile  ? selectedFile.name : null}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="right" width="40%">
                                                            <Box display="flex" alignItems="center">
                                                                <Box width="100%" mr={1}>
                                                                        <BorderLinearProgress variant="determinate" value={uploadData.progressArray[index]} />
                                                                </Box>
                                                                <Box minWidth={35}>
                                                                        <Typography variant="body2" color="textSecondary">{`${uploadData.progressArray[index]}%`}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} md={12} lg={7} className={classes.uploadLimitNoticeRow}>
                                    <Typography variant="body2" align="right">
                                        Maximum file upload size: {maxFileUploadSizeMB ? maxFileUploadSizeMB : '2'} MB
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    // Details, file uploader
                    <Grid container spacing={1} direction="column" style={{ width: '300px' }}>
                    {numberOfUploadedFiles > 1 && (
                        <Grid item >

                            <Button
                                style={uploadButtonProperties && uploadButtonProperties.style ? uploadButtonProperties.style : {}}
                                variant="text"
                                color={uploadButtonProperties && uploadButtonProperties.color ? uploadButtonProperties.color : ''}
                                size="small"
                                component="span"
                                disableElevation
                                disabled={isDownloadingAll}
                                onClick={handleFileDownloadAll}
                                fullWidth
                            >
                                {isDownloadingAll
                                    ? <CircularProgress size={15} style={{marginRight: 4}}/>
                                    : <Download fontSize="small"  style={{marginRight: 4}}/>
                                }
                                {`${t(
                                    "downloadAllAttachments"
                                )} (${numberOfUploadedFiles})`}
                            </Button>
                        </Grid>
                    )}
                    {disabled == false &&
                        <Grid item >
                            <label htmlFor="btn-upload">
                                <input
                                    id="btn-upload"
                                    name="btn-upload"
                                    data-cy="cy-fileUploader-hidden-upload-btn"
                                    style={{display: 'none'}}
                                    type="file"
                                    multiple
                                    onClick={(e) => {
                                        e.target.value = "";
                                    }}
                                    onChange={handleFileUpload}/>

                                <Button
                                    data-cy="cy-fileUploader-addAttachment-btn"
                                    style={uploadButtonProperties && uploadButtonProperties.style ? uploadButtonProperties.style : {}}
                                    variant={uploadButtonProperties && uploadButtonProperties.variant ? uploadButtonProperties.variant : ''}
                                    color={uploadButtonProperties && uploadButtonProperties.color ? uploadButtonProperties.color : ''}
                                    size="small"
                                    component="span"
                                    disableElevation
                                    fullWidth
                                >
                                    {isUploading
                                        ? <CircularProgress size={15} style={{marginRight: 4}}/>
                                        : <AttachFileIcon fontSize="small"  style={{marginRight: 4}}/>
                                    }
                                    {uploadButtonLabel ? ' ' + uploadButtonLabel : ''}
                                </Button>
                            </label>      
                        </Grid>
                    }
                    </Grid>
                )
            }
            <AlertDialog
                openState={!!uploadData.message}
                title="Attachment Upload"
                text={uploadData.message}
                onClose={function(){
                    setUploadData((prevState) => ({
                        ...prevState,
                        message: '',
                        isError: false
                    }));
                }}
            />
        </React.Fragment>
    );

}

export default UploadFile;